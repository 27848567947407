import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IPlayer} from "data/stores/players/players.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamMessageStore} from "data/stores/team_message/team_message.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RoundStatus} from "data/enums";
import {chain, every, isEmpty, some} from "lodash";

interface IParam {
	player: IPlayer;
}

export interface IPlayerPoolCardController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isInTeam(): boolean;
	get canAddToTeam(): boolean;
	get isAddButtonVisible(): boolean;
	get isRemoveButtonVisible(): boolean;
	get isLocked(): boolean;
	get displayedStat(): string;
	get nextOpponents(): string;
	get squad(): ISquad | undefined;
	get lockedText(): string;
	get className(): string;
	removeFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addToTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	openPlayerModal: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

@injectable()
export class PlayerPoolCardController implements IPlayerPoolCardController {
	@observable private _player: IPlayer | null = null;

	get isInTeam() {
		if (!this._player) {
			return false;
		}

		return this._teamStore.lineupIDs.includes(this._player.id);
	}

	get canAddToTeam() {
		if (!this._player) {
			return false;
		}

		return this._teamStore.canAddPlayerToTeam(this._player);
	}

	get isComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get isPlaying() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Playing;
	}

	get isPlayerLocked() {
		if (!this._player) {
			return false;
		}

		return this.isPlaying && this._player.locked;
	}

	get isLocked() {
		if (this.isComplete || this.isPlayerLocked) {
			return true;
		}

		if (this.isInTeam) {
			return false;
		}

		return some([!this.canAddToTeam, this._teamStore.isLineupFullFilled]);
	}

	get isAddButtonVisible() {
		return every([!this.isInTeam, !this.isLocked, this.canAddToTeam]);
	}

	get isRemoveButtonVisible() {
		return every([this.isInTeam, !this.isLocked]);
	}

	get displayedStat() {
		return this._teamBuilderStore.filters.stat;
	}

	get nextOpponents() {
		if (!this._player) {
			return "-";
		}

		const squadId = this._player.squadId;

		const roundOpponents = this._squadsStore.getRoundOpponents(squadId);

		if (isEmpty(roundOpponents)) {
			return "-";
		}

		return chain(roundOpponents)
			.map(({game, squad}) => {
				const homeOrAway = game.awayId === squadId ? "(A)" : "(H)";
				return `${squad.abbreviation} ${homeOrAway}`;
			})
			.join(", ")
			.value();
	}

	get squad() {
		if (!this._player) {
			return;
		}
		return this._squadsStore.getSquadById(this._player.squadId);
	}

	get lockedText() {
		if (!this._player) {
			return this.i18n.t("player_pool.locked_message.locked", "Player is locked");
		}

		if (this.isComplete) {
			return this.i18n.t("player_pool.locked_message.complete", "Gameweek is complete");
		}

		if (this.isPlayerLocked) {
			return this.i18n.t("player_pool.locked_message.locked", "Player is locked");
		}

		const {squadId} = this._player;
		if (!this._teamStore.canAddFromSquad(squadId)) {
			return this.i18n.t("player_pool.locked_message.team_limit", "Team limit reached");
		}

		return this.i18n.t("player_pool.locked_message.position_full", "Position is full");
	}

	get className() {
		if (this.isInTeam) {
			return "in-team";
		}

		if (this.isLocked) {
			return "locked";
		}

		return "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamMessageStore) private _teamMessageStore: ITeamMessageStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action
	public removeFromTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		if (!this._player) {
			return;
		}

		this._teamStore.removeFromTeam(this._player.id);
	};

	@action
	public addToTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		if (!this._player) {
			return;
		}

		this._teamStore.addToTeam(this._player.id);
		this._teamMessageStore.setMessageToShow(
			this.i18n.t(
				"player_pool.message.player_added",
				"{{name}} has been added to your team",
				{name: this._player.displayName}
			)
		);
	};

	public openPlayerModal = (e: React.SyntheticEvent<HTMLDivElement>) => {
		if (!this._player) {
			return;
		}
		this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {playerId: this._player.id});
	};

	init(param: IParam): void {
		this._player = param.player;
	}

	onChange(param: IParam): void {
		this._player = param.player;
	}
}
