import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {SortOrder} from "data/enums";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {chain, isEmpty} from "lodash";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";

export interface IPlayerPoolSquadsController extends ViewController {
	readonly i18n: ILocalizationStore;
	get squads(): ISquad[];
	get selectedStatLabel(): string;
	get order(): SortOrder;
	get isGridView(): boolean;
	get isSortedByCap(): boolean;
	get isSeasonStarted(): boolean;
	get emptyPlayersMessage(): string;
	sortSquads: () => void;
	sortSquadsByCap: () => void;
}

@injectable()
export class PlayerPoolSquadsController implements IPlayerPoolSquadsController {
	get squads(): ISquad[] {
		return this._squadsStore.getFilteredSquads(this._teamStore.team.squadSelections);
	}

	get selectedStatLabel(): string {
		return this._teamBuilderStore.selectedStatLabel;
	}

	get order(): SortOrder {
		return this._teamBuilderStore.order;
	}

	get isGridView(): boolean {
		return this._teamBuilderStore.isPoolGridView;
	}

	get isSortedByCap(): boolean {
		return this._teamBuilderStore.isPoolSortedByCap;
	}

	get isSeasonStarted(): boolean {
		return this._roundsStore.isSeasonStarted;
	}

	private get filteredCompetitions(): string[] {
		return this._teamBuilderStore.filters.competition;
	}

	private get isFilterCompetitionsEmpty(): boolean {
		return isEmpty(this.filteredCompetitions) || this.filteredCompetitions.includes("null");
	}

	get emptyPlayersMessage(): string {
		const defaultMessage = this.i18n.t(
			"player_pool.search.player_not_found",
			"No players found"
		);

		if (this.isFilterCompetitionsEmpty || this.filteredCompetitions.length > 1) {
			return defaultMessage;
		}

		const competitionId = chain(this.filteredCompetitions).first().toNumber().value();
		const competition = this._competitionsStore.getCompetitionById(competitionId);
		const roundId = this._roundsStore.selectedRound?.id;
		if (!competition || !roundId) {
			return defaultMessage;
		}

		const games = this._roundsStore.getGamesByCompetitionId(competitionId, roundId);

		if (isEmpty(games)) {
			return this.i18n.t(
				"pool.copy.no_results_found_league",
				"There are no {{competition}} fixtures to display for this gameweek.",
				{
					competition: competition.name,
				}
			);
		}

		return defaultMessage;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore
	) {
		makeAutoObservable(this);
	}

	public sortSquads = () => {
		if (this._teamBuilderStore.isPoolSortedByCap) {
			this._teamBuilderStore.isPoolSortedByCap = false;
			return;
		}
		this._teamBuilderStore.sortPlayerPool();
	};

	public sortSquadsByCap = () => {
		if (!this._teamBuilderStore.isPoolSortedByCap) {
			this._teamBuilderStore.isPoolSortedByCap = true;
			return;
		}
		this._teamBuilderStore.sortPlayerPool();
	};
}
