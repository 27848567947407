import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {GameStatus, RoundStatus} from "data/enums";
import {chain, first, last} from "lodash";

export interface IGame {
	awayId: number;
	awayScore: number;
	competitionId: number;
	date: string;
	homeId: number;
	homeScore: number;
	id: number;
	status: GameStatus;
}

export interface IRound {
	id: number;
	lockoutDate: string;
	status: RoundStatus;
	games: IGame[];
}

export interface IRoundsStore {
	get getIsLoading(): boolean;

	get isRoundLoading(): boolean;

	set isRoundLoading(isRoundLoading: boolean);

	get list(): IRound[];

	get scheduleRounds(): IRound[];

	get completedRounds(): IRound[];

	get completedAndActiveRounds(): IRound[];

	get currentRound(): IRound | undefined;

	get scoreRound(): IRound | null;

	get selectedRoundId(): number | null;

	set selectedRoundId(id: number | null);

	get selectedRound(): IRound | undefined;

	get isSeasonStarted(): boolean;

	get games(): IGame[];

	get scheduledGames(): IGame[];

	get activeGames(): IGame[];

	getRoundById(roundId: number): IRound | undefined;

	getGameById(gameId: number): IGame | undefined;

	getGamesByRoundId(roundId: number): IGame[];

	getGamesByCompetitionId(competitionId: number, roundId?: number): IGame[];

	getSquadNextFixture(squadId: number): IGame | undefined;

	getSquadRoundFixture(squadId: number, roundId?: number): IGame | undefined;

	getSquadRoundFixtures(squadId: number, roundId?: number): IGame[];

	getSquadNextFixtures(squadId: number, count: number): IGame[];

	isSquadPlayingInRound(squadId: number, roundId: number): boolean;

	isSquadPlaying(squadId: number): boolean;

	isAllSquadGamesPlayed(squadId: number, roundId: number): boolean;

	fetchRounds(): Promise<void>;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;
	@observable private _selectedRoundId: number | null = null;
	@observable private _isRoundLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IRound[] = [];

	get list() {
		return this._list;
	}

	get scheduleRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Complete);
	}

	get completedAndActiveRounds() {
		return this._list.filter(
			(e) => e.status === RoundStatus.Complete || e.status === RoundStatus.Playing
		);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get isRoundLoading(): boolean {
		return this._isRoundLoading;
	}

	set isRoundLoading(value: boolean) {
		this._isRoundLoading = value;
	}

	get currentRound() {
		return this.activeRound || first(this.scheduleRounds) || last(this.list);
	}

	get scoreRound() {
		return this.activeRound || last(this.completedRounds) || null;
	}

	private get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Playing);
	}

	get selectedRoundId() {
		return this._selectedRoundId;
	}

	set selectedRoundId(id: number | null) {
		this._selectedRoundId = id;
	}

	get selectedRound() {
		const round = this.list.find((e) => e.id === this.selectedRoundId);
		if (round) {
			return round;
		}

		return this.currentRound;
	}

	get isSeasonStarted() {
		return this.list.some(({status}) => status !== RoundStatus.Scheduled);
	}

	get games() {
		return chain(this.list)
			.flatMap((round) => round.games)
			.filter((game) => game.status !== GameStatus.Postponed)
			.orderBy("date")
			.value();
	}

	get scheduledGames() {
		return this.games.filter((game) => game.status === GameStatus.Scheduled);
	}

	get activeGames() {
		return this.games.filter((game) => game.status === GameStatus.Playing);
	}

	getRoundById = (roundId: number) => {
		return this.list.find((round) => round.id === roundId);
	};

	getGameById = (gameId: number) => {
		return this.games.find((game) => game.id === gameId);
	};

	getGamesByRoundId = (roundId: number) => {
		const round = this.getRoundById(roundId);
		if (!round) {
			return [];
		}

		return chain(round.games)
			.filter((game) => game.status !== GameStatus.Postponed)
			.orderBy("date")
			.value();
	};

	getGamesByCompetitionId = (competitionId: number, roundId?: number) => {
		const games = roundId ? this.getGamesByRoundId(roundId) : this.games;
		if (!games) {
			return [];
		}

		return games.filter((game) => game.competitionId === competitionId);
	};

	getSquadNextFixture = (squadId: number) => {
		return this.scheduledGames.find(
			(game) => game.homeId === squadId || game.awayId === squadId
		);
	};

	getSquadRoundFixture = (squadId: number, roundId?: number) => {
		const round = roundId ? this.getRoundById(roundId) : this.selectedRound;
		if (!round) {
			return;
		}

		return this.getGamesByRoundId(round.id).find(
			(game) => game.homeId === squadId || game.awayId === squadId
		);
	};

	getSquadRoundFixtures = (squadId: number, roundId?: number) => {
		const round = roundId ? this.getRoundById(roundId) : this.selectedRound;
		if (!round) {
			return [];
		}

		return this.getGamesByRoundId(round.id).filter(
			(game) => game.homeId === squadId || game.awayId === squadId
		);
	};

	getSquadNextFixtures = (squadId: number, count: number) => {
		return chain(this.scheduledGames)
			.filter((game) => game.homeId === squadId || game.awayId === squadId)
			.take(count)
			.value();
	};

	isSquadPlayingInRound = (squadId: number, roundId: number) => {
		return this.getGamesByRoundId(roundId).some(
			(game) => game.homeId === squadId || game.awayId === squadId
		);
	};

	isSquadPlaying = (squadId: number) => {
		return this.activeGames.some((game) => game.homeId === squadId || game.awayId === squadId);
	};

	isAllSquadGamesPlayed = (squadId: number, roundId: number) => {
		const games = this.getGamesByRoundId(roundId);
		return chain(games)
			.filter((game) => game.homeId === squadId || game.awayId === squadId)
			.every((game) => game.status === GameStatus.Complete)
			.value();
	};

	@action
	async fetchRounds() {
		const {data} = await this._jsonProvider.rounds();

		runInAction(() => {
			this._list = data;
		});
	}
}
