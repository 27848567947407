import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeamMessageStore} from "data/stores/team_message/team_message.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RoundStatus} from "data/enums";
import {chain, every, isEmpty, some} from "lodash";

interface IParam {
	squad: ISquad;
}

export interface IPlayerPoolSquadCardController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isInTeam(): boolean;
	get canAddToTeam(): boolean;
	get isLocked(): boolean;
	get isAddButtonVisible(): boolean;
	get isRemoveButtonVisible(): boolean;
	get displayedStat(): string;
	get nextOpponents(): string;
	get selectedCount(): number;
	get lockedText(): string;
	get className(): string;
	get clubCapClassName(): string;
	get isSeasonStarted(): boolean;
	get showClubCap(): boolean;
	removeFromTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	addToTeam: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	openSquadModal: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

@injectable()
export class PlayerPoolSquadCardController implements IPlayerPoolSquadCardController {
	@observable private _squad: ISquad | null = null;

	get isInTeam() {
		if (!this._squad) {
			return false;
		}

		return this._teamStore.lineupSquads.includes(this._squad.id);
	}

	get canAddToTeam() {
		if (!this._squad) {
			return false;
		}

		return this._teamStore.canAddSquadToTeam(this._squad);
	}

	get isComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get isPlaying() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Playing;
	}

	get isSquadLocked() {
		if (!this._squad) {
			return false;
		}

		return this.isPlaying && this._squad.locked;
	}

	get isLocked() {
		if (this.isComplete || this.isSquadLocked) {
			return true;
		}

		if (this.isInTeam) {
			return false;
		}

		return some([!this.canAddToTeam, this._teamStore.isSquadsFullFilled]);
	}

	get isAddButtonVisible() {
		return every([!this.isInTeam, !this.isLocked, this.canAddToTeam]);
	}

	get isRemoveButtonVisible() {
		return every([this.isInTeam, !this.isLocked]);
	}

	get displayedStat() {
		return this._teamBuilderStore.filters.stat;
	}

	get nextOpponents() {
		if (!this._squad) {
			return "-";
		}

		const squadId = this._squad.id;

		const roundOpponents = this._squadsStore.getRoundOpponents(squadId);

		if (isEmpty(roundOpponents)) {
			return "-";
		}

		return chain(roundOpponents)
			.map(({game, squad}) => {
				const homeOrAway = game.awayId === squadId ? "(A)" : "(H)";
				return `${squad.abbreviation} ${homeOrAway}`;
			})
			.join(", ")
			.value();
	}

	get selectedCount() {
		if (!this._squad) {
			return 0;
		}

		return this._teamStore.getSquadSelectedCount(this._squad.id);
	}

	get lockedText() {
		if (!this._squad) {
			return this.i18n.t("player_pool.club_locked_message.locked", "Club is locked");
		}

		if (this._teamStore.isSquadCapReached(this._squad.id)) {
			return this.i18n.t(
				"player_pool.club_locked_message.cap_reached",
				"Selection cap has been reached"
			);
		}

		if (this.isComplete) {
			return this.i18n.t("player_pool.club_locked_message.complete", "Gameweek is complete");
		}

		if (this.isSquadLocked) {
			return this.i18n.t("player_pool.club_locked_message.locked", "Club is locked");
		}

		return this.i18n.t("player_pool.club_locked_message.clubs_full", "Your clubs are full");
	}

	get className() {
		if (this.isInTeam) {
			return "in-team";
		}

		if (this.isLocked) {
			return "locked";
		}

		return "";
	}

	get clubCapClassName() {
		return this.selectedCount > 0 ? "selected" : "unselected";
	}

	get isSeasonStarted() {
		return this._roundsStore.isSeasonStarted;
	}

	get showClubCap() {
		return this.isSeasonStarted && this.selectedCount < 5;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TeamMessageStore) private _teamMessageStore: ITeamMessageStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action
	public removeFromTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		if (!this._squad) {
			return;
		}

		this._teamStore.removeSquadFromTeam(this._squad.id);
	};

	@action
	public addToTeam = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		if (!this._squad) {
			return;
		}

		this._teamStore.addSquadToTeam(this._squad.id);
		this._teamMessageStore.setMessageToShow(
			this.i18n.t("player_pool.message.club_added", "{{name}} have been added to your team", {
				name: this._squad.name,
			})
		);
	};

	public openSquadModal = (e: React.SyntheticEvent<HTMLDivElement>) => {
		if (!this._squad) {
			return;
		}
		this._modalsStore.showModal(ModalType.SQUAD_PROFILE, {squadId: this._squad.id});
	};

	init(param: IParam): void {
		this._squad = param.squad;
	}

	onChange(param: IParam): void {
		this._squad = param.squad;
	}
}
