import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IRanking} from "data/providers/api/rankings.api.provider";
import {defaultTo, isNumber} from "lodash";
import {RankStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParam {
	standing: IRanking;
}

export interface IRankingsRowController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get prevRank(): number | "--";
	get rank(): number | "--";
	get week(): number | "--";
	get total(): number | "--";
	get rankStatus(): RankStatus | null;
	get canViewTeam(): boolean;
}

@injectable()
export class RankingsRowController implements IRankingsRowController {
	@observable private _standing: IRanking | null = null;

	get prevRank(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		return defaultTo(this._standing.prevRank, "--");
	}

	get rank(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		return defaultTo(this._standing.rank, "--");
	}

	get week(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		return defaultTo(this._standing.roundPoints, "--");
	}

	get total(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		return defaultTo(this._standing.overallPoints, "--");
	}

	get rankStatus(): RankStatus | null {
		if (!this._standing) {
			return null;
		}

		const {rank, prevRank} = this._standing;

		if (!isNumber(rank) || !isNumber(prevRank)) {
			return null;
		}

		if (rank === prevRank) {
			return RankStatus.SAME;
		}

		return prevRank > rank ? RankStatus.UP : RankStatus.DOWN;
	}

	get canViewTeam(): boolean {
		return Boolean(this._roundsStore.scoreRound);
	}

	constructor(
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._standing = param.standing;
	}

	onChange(param: IParam): void {
		this._standing = param.standing;
	}
}
