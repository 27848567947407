export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	TEAM_SAVED,
	PLAYER_PROFILE,
	REGISTRATION,
	LEAGUE_JOIN,
	SAVE_TEAM,
	SQUAD_PROFILE,
	TUTORIAL,
	CAPTAIN_SELECT,
	BOOSTERS,
	CLUB_CAP,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "completed",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league_invite",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
	CLUB = "club",
	CELEBRITY = "celebrity",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum RankStatus {
	UP,
	DOWN,
	SAME,
}

export enum PlayerPosition {
	GK = "GK",
	DEF = "DEF",
	MID = "MID",
	FWD = "FWD",
}

export enum PlayerStatus {
	Playing = "playing",
	Injured = "injured",
	Suspended = "suspended",
	Eliminated = "eliminated",
}

export enum CreateTeamSteps {
	PickTeam = "pickTeam",
	PickSquads = "pickSquads",
	PickCaptain = "pickCaptain",
	PickViceCaptain = "pickViceCaptain",
	UpdateTeam = "updateTeam",
}

export enum PlayerPoolType {
	Player = "player",
	Squad = "squad",
}

export enum PlayerPoolStat {
	PercentSelected = "percentSelected",
	TotalPoints = "totalPoints",
	RoundPoints = "roundPoints",
	AveragePoints = "averagePoints",
}

export enum PlayerPitchStat {
	PercentSelected = "percentSelected",
	TotalPoints = "totalPoints",
	RoundPoints = "roundPoints",
	NextFixture = "nextFixture",
}

export enum GameStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "completed",
	Postponed = "postponed",
}

export enum ProfileTab {
	Overview = "overview",
	Stats = "stats",
	Results = "results",
}

export enum CompetitionCode {
	Championship = "CH",
	LeagueOne = "L1",
	LeagueTwo = "L2",
}
