import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {CompetitionCode} from "data/enums";

export interface ICompetition {
	id: number;
	code: CompetitionCode;
	name: string;
}

export interface ICompetitionsStore {
	get getIsLoading(): boolean;
	get list(): ICompetition[];
	getCompetitionById(competitionId: number): ICompetition | undefined;
	fetchCompetitions(): Promise<void>;
}

@injectable()
export class CompetitionsStore implements ICompetitionsStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: ICompetition[] = [];

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getCompetitionById(competitionId: number): ICompetition | undefined {
		return this._list.find((competition) => competition.id === competitionId);
	}

	@action
	async fetchCompetitions() {
		const {data} = await this._jsonProvider.competitions();

		runInAction(() => {
			this._list = data;
		});
	}
}
