import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import React from "react";
import {
	CreateTeamSteps,
	GameStatus,
	ModalType,
	PlayerPitchStat,
	PlayerStatus,
	RoundStatus,
} from "data/enums";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {chain, isEmpty, isNull, isNumber} from "lodash";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

interface IParam {
	playerId: number;
}

export interface IPlayerCardController extends ViewController<IParam> {
	get player(): IPlayer | undefined;
	get squad(): ISquad | undefined;
	get isCapSelection(): boolean;
	get isCaptain(): boolean;
	get isCaptainDisabledIconVisible(): boolean;
	get isViceCapSelection(): boolean;
	get isViceCaptain(): boolean;
	get isViceCaptainDisabledIconVisible(): boolean;
	get isMaxCaptain(): boolean;
	get showIcons(): boolean;
	get showStatusIcon(): boolean;
	get isLocked(): boolean;
	get className(): string;
	get nextOpponents(): string;
	get displayStat(): string | number;
	get fontSize(): number | undefined;
	handleClick: (e: React.SyntheticEvent<HTMLDivElement>) => void;
	selectCaptain: () => void;
}

@injectable()
export class PlayerCardController implements IPlayerCardController {
	@observable private _playerId = 0;

	get player() {
		return this._playersStore.getPlayerById(this._playerId);
	}

	get squad() {
		if (!this.player) {
			return;
		}
		return this._squadsStore.getSquadById(this.player.squadId);
	}

	get isCapSelection() {
		return this._teamStore.teamCreationStep.step === CreateTeamSteps.PickCaptain;
	}

	get isCaptain() {
		return (
			!this._teamStore.team.maxCaptainEnabled &&
			this._teamStore.team.captainId === this._playerId
		);
	}

	get isCaptainDisabledIconVisible() {
		return !this.isViceCaptain && !this.isCaptain && this.isCapSelection;
	}

	get isViceCapSelection() {
		return this._teamStore.teamCreationStep.step === CreateTeamSteps.PickViceCaptain;
	}

	get isViceCaptain() {
		return (
			!this._teamStore.team.maxCaptainEnabled &&
			this._teamStore.team.viceCaptainId === this._playerId
		);
	}

	get isViceCaptainDisabledIconVisible() {
		return !this.isCaptain && !this.isViceCaptain && this.isViceCapSelection;
	}

	get isMaxCaptain() {
		return this._teamStore.getMaxCaptain(this._teamStore.team) === this._playerId;
	}

	get isMaxCaptainActive() {
		if (this.isMaxCaptain) {
			return true;
		}
		return (
			!this.isRoundComplete &&
			!this._teamStore.isTeamPlayerLocked &&
			this._teamStore.team.maxCaptainEnabled
		);
	}

	get isRoundComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get isRoundPlaying() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Playing;
	}

	get showIcons() {
		return !this.isRoundComplete && !this.isCapSelection && !this.isViceCapSelection;
	}

	get showStatusIcon() {
		if (!this.player) {
			return false;
		}
		return this.player.status !== PlayerStatus.Playing;
	}

	get isPlaying() {
		if (!this.player) {
			return false;
		}
		return this.isRoundPlaying && this._roundsStore.isSquadPlaying(this.player.squadId);
	}

	get isLocked() {
		if (!this.player) {
			return false;
		}
		return this.isRoundPlaying && this.player.locked;
	}

	get className() {
		if (this.isPlaying) {
			return "playing";
		}
		if (this.isMaxCaptainActive) {
			return "max-captain";
		}
		if (this.isCaptain) {
			return this.isViceCapSelection ? "captain disabled" : "captain";
		}
		if (this.isViceCaptain) {
			return this.isCapSelection ? "vice-captain disabled" : "vice-captain";
		}
		return "";
	}

	get nextOpponents() {
		if (!this.player) {
			return "-";
		}

		const squadId = this.player.squadId;

		const roundOpponents = this._squadsStore.getRoundOpponents(squadId);

		if (isEmpty(roundOpponents)) {
			return "-";
		}

		return chain(roundOpponents)
			.map(({squad, game}) => {
				const homeOrAway = game.awayId === squadId ? "(A)" : "(H)";
				return `${squad.abbreviation} ${homeOrAway}`;
			})
			.join(", ")
			.value();
	}

	get fontSize() {
		if (!this.player) {
			return;
		}
		const roundOpponents = this._squadsStore.getRoundOpponents(this.player.squadId);
		const isMultipleOpponents = roundOpponents.length > 1;
		const isNextFixture =
			this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.NextFixture;
		if (!this.isLocked && isMultipleOpponents && isNextFixture) {
			return 11;
		}
	}

	private getDisplayValue(value: number | null, suffix: string = "") {
		return isNumber(value) ? `${value}${suffix}` : "-";
	}

	private getRoundPoints(suffix: string = "") {
		if (!this._roundsStore.selectedRound) {
			return "-";
		}
		const points = this._teamStore.getPlayerRoundPoints(
			this._playerId,
			this._roundsStore.selectedRound.id,
			undefined,
			this._teamStore.team
		);
		return isNull(points) ? "-" : `${points}${suffix}`;
	}

	private getLivePoints(suffix: string = "") {
		if (!this._roundsStore.selectedRound || !this.player) {
			return "-";
		}

		const selectedRound = this._roundsStore.selectedRound;
		const roundOpponents = this._squadsStore.getRoundOpponents(this.player.squadId);

		if (!roundOpponents.map(({game}) => game.status).includes(GameStatus.Scheduled)) {
			return this.getRoundPoints(suffix);
		}

		return chain(roundOpponents)
			.map(({squad, game}) => {
				if (game.status === GameStatus.Scheduled) {
					return squad.abbreviation;
				}
				const points = this._teamStore.getPlayerRoundPoints(
					this._playerId,
					selectedRound.id,
					game.id,
					this._teamStore.team
				);
				return isNull(points) ? "-" : points;
			})
			.join(", ")
			.value();
	}

	get displayStat() {
		if (!this.player) {
			return "";
		}

		if (this.isLocked) {
			return this.getLivePoints(" Pts");
		}

		if (this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.NextFixture) {
			return this.nextOpponents;
		}

		const suffix =
			this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.PercentSelected
				? "%"
				: " Pts";

		if (this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.RoundPoints) {
			return this.getRoundPoints(suffix);
		}

		const value = this.player[this._teamBuilderStore.selectedPitchStatOption];
		return this.getDisplayValue(value, suffix);
	}

	private get match() {
		if (!this.player) {
			return;
		}
		const match = this._roundsStore.getSquadRoundFixture(this.player.squadId);
		if (!match) {
			return;
		}
		const isRoundPoints =
			this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.RoundPoints;
		const isMatchComplete = match.status === GameStatus.Complete;
		const isMatchPlaying = match.status === GameStatus.Playing;
		const isMatchAvailable = isMatchComplete || isMatchPlaying;
		if ((isRoundPoints && isMatchAvailable) || isMatchPlaying) {
			return match;
		}
	}

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
		if (!this.player) {
			return;
		}
		if (this.isCapSelection) {
			this.selectCaptain();
			return;
		}
		if (this.isViceCapSelection) {
			this.selectViceCaptain();
			return;
		}
		if (this.match) {
			this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {
				playerId: this._playerId,
				matchId: this.match.id,
			});
			return;
		}
		this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {playerId: this._playerId});
	};

	@action
	selectCaptain = () => {
		if (!this._playerId || this.isViceCaptain) {
			return;
		}
		this._teamStore.selectCaptain(this._playerId);
	};

	@action
	selectViceCaptain = () => {
		if (!this._playerId || this.isCaptain) {
			return;
		}
		this._teamStore.selectViceCaptain(this._playerId);
	};

	init(param: IParam): void {
		this._playerId = param.playerId;
	}

	onChange(param: IParam): void {
		this._playerId = param.playerId;
	}
}
