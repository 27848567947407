import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {IGame, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {chain, forEach, get, orderBy} from "lodash";
import {SPECIAL_SYMBOLS} from "data/constants";

export interface ISquad {
	id: number;
	competitionId: number;
	name: string;
	abbreviation: string;
	darkBadge: string;
	lightBadge: string;
	jersey: string;
	locked: boolean;
	totalPoints: number;
	averagePoints: number;
	roundPoints: number | null;
	percentSelected: number;
	leaguePosition: number;
	last3Form: string[];
	backgroundColor: string;
	textColor: string;
}

export interface IStats {
	goalsScored: number;
	goalsConceded: number;
	cleanSheets: number;
	twoGoalsGames: number;
}

export interface IMatchStats {
	squadId: number;
	gameId: number;
	roundId: number;
	points: number;
	win: number;
	draw: number;
	awayWin: number;
	cleanSheet: number;
	goalsScored: number;
}

export interface ISquadStats {
	stats: IStats;
	results: IMatchStats[];
}

export interface INextOpponent {
	squad: ISquad;
	game: IGame;
}

export interface ISquadsStore {
	get getIsLoading(): boolean;
	get list(): ISquad[];
	getFilteredSquads(squadSelections: Record<number, number>): ISquad[];
	getSquadById(squadId: number): ISquad | undefined;
	getSquadStatsById(squadId: number): ISquadStats | undefined;
	getNextOpponent(squadId: number): ISquad | undefined;
	getRoundOpponent(squadId: number): ISquad | undefined;
	getRoundOpponents(squadId: number): INextOpponent[];
	fetchSquads(): Promise<void>;
	fetchSquadStats(squadId: number): Promise<void>;
}

@injectable()
export class SquadsStore implements ISquadsStore {
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	@observable private _list: ISquad[] = [];
	@observable private _squadStats: Record<number, ISquadStats> = {};

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getFilteredSquads(squadSelections: Record<number, number>): ISquad[] {
		const {search, competition, stat} = this._teamBuilderStore.filters;
		const squads = this.list.filter((squad) => {
			return (
				this.isInName(search, squad) &&
				this.isInCompetition(competition, squad) &&
				this.isPlayingInRound(squad)
			);
		});

		if (this._teamBuilderStore.isPoolSortedByCap) {
			return orderBy(
				squads,
				[(squad) => get(squadSelections, squad.id, 0), "name"],
				[this._teamBuilderStore.order]
			);
		}

		return orderBy(
			squads,
			[(squad) => squad[stat] ?? 0, "name"],
			[this._teamBuilderStore.order]
		);
	}

	getSquadById(squadId: number): ISquad | undefined {
		return this._list.find((squad) => squad.id === squadId);
	}

	getNextOpponent(squadId: number) {
		const nextFixture = this._roundsStore.getSquadNextFixture(squadId);
		if (!nextFixture) {
			return;
		}

		const squadIdNext =
			nextFixture.homeId === squadId ? nextFixture.awayId : nextFixture.homeId;
		return this.getSquadById(squadIdNext);
	}

	getRoundOpponent(squadId: number) {
		const fixture = this._roundsStore.getSquadRoundFixture(squadId);
		if (!fixture) {
			return;
		}

		const squadIdNext = fixture.homeId === squadId ? fixture.awayId : fixture.homeId;
		return this.getSquadById(squadIdNext);
	}

	getRoundOpponents(squadId: number) {
		const fixtures = this._roundsStore.getSquadRoundFixtures(squadId);
		return chain(fixtures)
			.map((game) => {
				const squadIdNext = game.homeId === squadId ? game.awayId : game.homeId;
				const squad = this.getSquadById(squadIdNext);
				return squad ? {squad, game} : null;
			})
			.compact()
			.value();
	}

	getSquadStatsById(squadId: number): ISquadStats | undefined {
		return this._squadStats[squadId];
	}

	private isInName(searchValue: string, squad: ISquad): boolean {
		if (!searchValue.length) {
			return true;
		}
		let fullName = squad.name.toLowerCase();
		let enteredName = searchValue.toLowerCase();

		forEach(SPECIAL_SYMBOLS, (item, key) => {
			fullName = fullName.replace(key, item);
			enteredName = enteredName.replace(key, item);
		});

		return fullName.includes(enteredName);
	}

	private isInCompetition(competitions: string[], squad: ISquad) {
		if (!competitions.filter((val) => val !== "null").length) {
			return true;
		}

		return competitions.includes(squad.competitionId.toString());
	}

	private isPlayingInRound(squad: ISquad) {
		const roundId = get(this._roundsStore.currentRound, "id", 0);
		return this._roundsStore.isSquadPlayingInRound(squad.id, roundId);
	}

	@action
	async fetchSquads() {
		const {data} = await this._jsonProvider.squads();

		runInAction(() => {
			this._list = data;
		});
	}

	@action
	async fetchSquadStats(squadId: number) {
		const {data} = await this._jsonProvider.squadStats(squadId);

		if (!Array.isArray(data.results)) {
			throw new Error("Error while loading squad info");
		}

		runInAction(() => {
			this._squadStats[squadId] = data;
		});
	}
}
